<template>
  <v-card
    outlined
    class="mb-2"
    >
    <v-card-text
      class="d-flex flex-row justify-space-between align-center py-2"
      >
      <div>
        {{ store.name }}
      </div>

      <div>
        <v-btn
          icon
          color="error"
          @click="removeFromGroup"
          >
          <v-icon>
            mdi-account-minus
          </v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { RemoveStoreFromGroup } from '@/graphql/mutations/groups'

export default {
  props: {
    store: {
      required: true,
      type: Object
    },

    groupId: {
      required: true,
      type: String | Number
    }
  },

  methods: {
    removeFromGroup () {
      this.$apollo.mutate({
        mutation: RemoveStoreFromGroup,
        variables: {
          input: {
            id: this.groupId,
            storeId: this.store.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
      })
    }
  }
}
</script>
