<template>
  <v-dialog
    v-model="dialog"
    width="900"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        fab
        small
        depressed
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Crear grupo
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialog = false"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          class="d-flex flex-row"
          style="gap: 12px"
          >
          <v-text-field
            outlined
            label="Nombre"
            v-model="attributes.name"
            :rules="[ v => !!v || 'Campo obligatorio']"
            ></v-text-field>

          <v-text-field
            outlined
            label="Comisión"
            v-model.number="attributes.commissionAttributes.amount"
            type="number"
            suffix="%"
            :rules="[ 
              v => v >= 0 || 'Debe ser mayor o igual a 0',
              v => (!!v || v === 0) || 'Campo obligatorio'
            ]"
            ></v-text-field>
        </v-form>

        <div
          class="text-right"
          >
          <v-btn
            depressed
            tile
            color="primary"
            @click="save"
            >
            Guardar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { CreateGroup } from '@/graphql/mutations/groups'

export default {
  data: () => ({
    dialog: false,
    valid: true,
    attributes: {
      name: '',
      commissionAttributes: {
        amount: null
      }
    }
  }),

  methods: {
    save () {
      if (this.$refs.form.validate ()) {
        this.$apollo.mutate({
          mutation: CreateGroup,
          variables: {
            input: {
              attributes: this.attributes,
            }
          }
        }).then ( res => {
          this.$emit('reload')
          this.dialog = false
        })
      }
    }
  }
}
</script>
