import gql from 'graphql-tag'

export const Groups = gql`
  query groups {
    groups {
      id
      name
      commission {
        id
        amount
      }
      stores {
        id
        name
      }
    }
  } 
`

export const GroupStores = gql`
  query groupStores($id: ID!) {
    groupStores(id: $id) {
      id
      name
    }
  } 
`
