<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :fullscreen="$vuetify.breakpoint.mobile"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="success"
        icon
        small
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>
          mdi-account-plus
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="pb-0"
        >
        Partners en el grupo
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialog = false"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="pt-3"
        >
        <v-form
          v-model="valid"
          ref="form"
          lazy-validation
          class="d-flex flex-row align-center"
          style="gap: 12px"
          >
          <v-autocomplete
            label="Añadir Partners"
            :items="partners"
            item-value="id"
            item-text="name"
            v-model="stores"
            outlined
            hide-details
            dense
            multiple
            ></v-autocomplete>

          <v-btn
            depressed
            tile
            color="primary"
            @click="save"
            :disabled="!stores.length"
            >
            Añadir
          </v-btn>
        </v-form>

        <v-divider
          class="my-3"
          ></v-divider>
        
        <div
          class="mb-3"
          >
          Listado de tiendas
        </div>

        <div>
          <template
            v-for="store in group.stores"
            >
            <store
              :store="store"
              :groupId="group.id"
              @reload="reload"
              ></store>
          </template>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { GroupStores } from '@/graphql/queries/groups'
import { AddStoresToGroup } from '@/graphql/mutations/groups'

import Store from './GroupStore'

export default {
  data: () => ({
    dialog: false,
    valid: true,
    partners: [],
    stores: [],
  }),

  props: {
    group: {
      required: true,
      type: Object
    }
  },

  created () {
    this.fetchPartners ()
  },

  methods: {
    fetchPartners () {
      this.$apollo.query({
        query: GroupStores,
        variables: {
          id: this.group.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.partners = res.data.groupStores
      })
    },

    save () {
      if (this.$refs.form.validate ()) {
        this.$apollo.mutate({
          mutation: AddStoresToGroup,
          variables: {
            input: {
              storeIds: this.stores,
              id: this.group.id
            }
          }
        }).then ( res => {
          this.$emit('reload')
          this.stores = []
          this.fetchPartners ()
        })
      }
    },
    
    reload () {
      this.$emit('reload')
      this.fetchPartners ()
    }
  },

  components: { Store }
}
</script>
