<template>
  <v-dialog
    v-model="dialog"
    width="900"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        icon
        small
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Eliminar grupo
        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialog = false"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <div
          class="text-subtitle-1 mb-2"
          >
          ¿Está seguro de que desea eliminar el grupo <span class="font-weight-medium primary--text">{{ group.name }}</span>? Esta acción <span class="font-weight-medium error--text">no se puede deshacer</span>.
        </div>

        <div
          class="justify-end d-flex flex-row"
          style="gap: 12px"
          >
          <v-btn
            text
            @click="dialog = false"
            >
            Cancelar
          </v-btn>

          <v-btn
            depressed
            tile
            color="error"
            @click="save"
            >
            Eliminar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { DeleteGroup } from '@/graphql/mutations/groups'

export default {
  data: () => ({
    dialog: false,
  }),

  props: {
    group: {
      required: true,
      type: Object
    }
  },

  methods: {
    save () {
      this.$apollo.mutate({
        mutation: DeleteGroup,
        variables: {
          input: {
            id: this.group.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
        this.dialog = false
      })
    }
  }
}
</script>
