<template>
  <div>
    <div
      class="d-flex text-h6 font-weight-medium mb-4 align-center black--text"
      >
      Grupos

      <v-spacer></v-spacer>

      <create
        @reload="fetchGroups"
        ></create>
    </div>
    <div
      class="font-weight-medium primary--text"
      >
      <template
        v-for="group in groups"
        >
        <group
          @reload="fetchGroups"
          :key="group.id"
          :group="group"
          ></group>
      </template>
    </div>
  </div>
</template>

<script>
import { Groups } from '@/graphql/queries/groups'
import Group from '@/components/partners_fans/commissions/Group'
import Create from '@/components/partners_fans/commissions/CreateGroup'

export default {
  data: () => ({
    groups: []
  }),

  created () {
    this.fetchGroups ()
  },

  methods: {
    fetchGroups () {
      this.$apollo.query({
        query: Groups,
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.groups = res.data.groups
      })
    }
  },

  components: { Group, Create }
}
</script>
