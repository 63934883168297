<template>
  <v-card
    outlined
    :style="'border-color: ' + $vuetify.theme.themes.light.primary"
    class="mb-2"
    >
    <v-card-text>
      <div
        class="d-flex flex-row justify-space-between align-center"
        >
        <div>
          <div>
            {{ group.name }}
          </div>

          <div
            class="caption"
            >
            Comisión: {{ group.commission.amount }}% | Partners: {{ group.stores.length }}
          </div>
        </div>

        <div
          class="d-flex flex-row"
          style="gap: 8px"
          >
          <add-stores
            :group="group"
            @reload="$emit('reload')"
            ></add-stores>

          <edit
            :group="group"
            @reload="$emit('reload')"
            ></edit>

          <delete
            :group="group"
            @reload="$emit('reload')"
            ></delete>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Edit from './EditGroup'
import Delete from './DeleteGroup'
import AddStores from './AddStoresToGroup'

export default {
  props: {
    group: {
      required: true,
      type: Object
    }
  },

  components: {
    Edit,
    Delete,
    AddStores
  }
}
</script>
