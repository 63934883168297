import gql from 'graphql-tag'

export const CreateGroup = gql`
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      success
    }
  }
`

export const EditGroup = gql`
  mutation editGroup($input: EditGroupInput!) {
    editGroup(input: $input) {
      success
    }
  }
`

export const DeleteGroup = gql`
  mutation deleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      success
    }
  }
`

export const AddStoresToGroup = gql`
  mutation addStoresToGroup($input: AddStoresToGroupInput!) {
    addStoresToGroup(input: $input) {
      success
    }
  }
`

export const RemoveStoreFromGroup = gql`
  mutation removeStoreFromGroup($input: RemoveStoreFromGroupInput!) {
    removeStoreFromGroup(input: $input) {
      success
    }
  }
`
